import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FloorplanViewModal from './helper/FloorplanViewModal'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiExpandAlt } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsData } from '../../../redux/actions';
import ScaleLoader from 'react-spinners/ScaleLoader';
function ProjectDetails({ hide }) {
    const fetchedProjectData = useSelector(state => state.fetchedProjectData)
    const { id } = useParams()
    const [open, setOpen] = useState(false)
    const [projId, setProjId] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProjectsData())
    }, [dispatch])
    const handleModal = (pid) => {
        setOpen(true)
        setProjId(pid)
    }
    const modal = () => {
        return (
            <FloorplanViewModal id={projId} setOpen={setOpen} />
        )
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1024 },
            items: 1.5,
            slidesToSlide: 1

        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 1.5,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 1.5,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };


    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-40'>

            {fetchedProjectData ? (
                <>
                    {
                        fetchedProjectData.filter(ap => ap.id === id).map((ap, i) => (
                            <div className="grid grid-cols-12 py-10 lg:py-12" key={i}>
                                <div className="col-span-12 ">
                                    <h2 className='font-visby leading-snug text-secondary text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-semibold'>{ap.projectName}</h2>
                                    <h5 className=' font-montserrat text-secondary text-xl sm:text-2xl md:text-3xl lg:text-4xl font-medium mt-5'>{ap.district}</h5>
                                </div>
                                <div className="col-span-12 duration-300">
                                    {
                                        open && (
                                            modal()

                                        )
                                    }
                                </div>
                                <div className="col-span-12 sm:col-span-8 md:col-span-7 lg:col-span-6 xl:col-span-4 mt-16">
                                    <div className="flex justify-between mt-3">
                                        <p className="text-secondary font-montserrat font-normal text-base sm:text-xl">LOCATION</p>
                                        <p className="text-secondary font-montserrat font-medium text-sm sm:text-lg">{ap.location}</p>
                                    </div>
                                    <div className="flex justify-between mt-3">
                                        <p className="text-secondary font-montserrat font-normal text-base sm:text-xl">{ap.type === "Interior" ? "CARPET AREA" : "PLOT AREA"}</p>
                                        <p className="text-secondary font-montserrat font-medium text-sm sm:text-lg">{ap.plotArea} sq.ft.</p>
                                    </div>
                                    {
                                        ap.type !== 'Industrial' && (
                                            <div className="flex justify-between mt-3">
                                                <p className="text-secondary font-montserrat font-normal text-base sm:text-xl">BUILT UP AREA</p>
                                                <p className="text-secondary font-montserrat font-medium text-sm sm:text-lg">{ap.builtUpArea} sq.ft.</p>
                                            </div>
                                        )
                                    }

                                    <div className="flex justify-between mt-3">
                                        <p className="text-secondary font-montserrat font-normal text-base sm:text-xl">COMPLETED IN</p>
                                        <p className="text-secondary font-montserrat font-medium text-sm sm:text-lg">{ap.completedIn}</p>
                                    </div>
                                </div>
                                <div className="col-span-12 md:col-span-5 lg:col-span-6 xl:col-span-6 mt-8 flex justify-center md:justify-end">
                                    <p onClick={() => handleModal(ap.id)} className="text-secondary cursor-pointer underline inline-block align-bottom font-visby mt-auto text-base sm:text-xl">View Floorplan</p>
                                </div>
                                <div className={`col-span-12 mt-10 relative ${hide | open ? "-z-10" : "z-0"}`}>

                                    <Carousel
                                        arrows={true}
                                        responsive={responsive}
                                        infinite={true}
                                        rewind={true}
                                        autoPlaySpeed={4000}
                                        transitionDuration={500}


                                    >
                                        {
                                            ap.gallery.map((tr, k) => (
                                                <div className="col-span-12 mx-1  justify-center" key={k}>
                                                    <img onContextMenu={e => e.preventDefault()} src={tr} id={k} alt="" className="mb-10 mt-4 w-full projectDetailsGalleryImg" />
                                                </div>

                                            ))
                                        }

                                    </Carousel>


                                    <div className='absolute bottom-14 right-5' >
                                        <BiExpandAlt size={25} className='rotate-90 text-white font-bold cursor-pointer' />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </>) : (

                <>
                    <div className='flex justify-center items-center my-80'>
                        <ScaleLoader size={100} color="black" />

                    </div>
                </>
            )}

        </div>
    )
}

export default ProjectDetails