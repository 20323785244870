import React, { useCallback, useEffect } from 'react'
import '../../../../styles/Projects/ProjectDetails.scss'
import {IoCloseSharp} from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsData } from '../../../../redux/actions'

function FloorplanViewModal({ id,setOpen }) {
    const dispatch = useDispatch()
    const fetchedProjectData = useSelector(state => state.fetchedProjectData)
    useEffect(() => {
        dispatch(getProjectsData())
    }, [dispatch])
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          setOpen(false)
        }
      }, [setOpen]);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction]);
    return (
        <>
        {
            fetchedProjectData&&fetchedProjectData.filter(k=>k.id===id).map(ot=>(
                <div key={ot.id} className="relative z-10 overflow-hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full md:h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
    
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full h-full floorplanViewModalBox">
                            <div className="bg-white p-4 sm:p-16">
                                <div className="flex items-start md:items-baseline justify-between">
                                    <div className='flex flex-col'>
                                    <h3 className='font-visby text-3xl md:text-4xl lg:text-5xl font-semibold'>{ot.name}</h3>
                                    <p className="text-secondary text-base md:text-lg text-end">{ot.builtUpArea} sq.ft.</p>
                                    </div>
                                  
                                <button onClick={()=>{
                                    
                                        setOpen(false)
                                      
                                }} type="button" className="text-secondary">
                                   <IoCloseSharp size={38}/>
                                </button>
                                </div>
                                <div className="flex">
                                    <img onContextMenu={e=>e.preventDefault()} src={ot.floorplan} alt="" className='w-full h-max' />
                                </div>
                             
                               
    
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            ))
        }
        </>
    )
}

export default FloorplanViewModal