import React, { useState } from 'react'
import '../../../styles/Career/QuestionsForm.scss'

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { careerUpload } from '../../../redux/actions';
import toast,{ Toaster } from 'react-hot-toast';
function QuestionsForm() {
    const dispatch = useDispatch()
    const [details, setdetails] = useState({
        email: "",
        fullName: "",
        phone: "",
        school: "",
        yearOfDegree: "",
        yearsOfExp: "",
        officeExp: "",
        softwares: []
    })
    const initState = {
        email: "",
        fullName: "",
        phone: "",
        school: "",
        yearOfDegree: "",
        yearsOfExp: "",
        officeExp: "",
        softwares: [],
    }
    const [cv, setCv] = useState(null)
    const [portfolio, setPortfolio] = useState(null)
    const handleInputChange = (e) => {
        e.persist()
        setdetails((inp) => ({
            ...inp,
            [e.target.name]: e.target.value
        }))
    }
    const onCvFileChange = (e) => {
        setCv(e.target.files[0])
    }
    const onPortfolioFileChange = (e) => {
        setPortfolio(e.target.files[0])
    }
    const handleSubmit = async () => {
        if (details.email === "" || details.fullName === "" || details.phone === "" || details.school === "" || details.yearOfDegree === "" || details.yearsOfExp === "" || details.officeExp === "" || (!cv && !portfolio)) {
            toast.error('Fill up the fields',{
                icon:'❗'
            });

        }
        else {
            let newdetails = {
                email: details.email,
                fullName: details.fullName,
                phone: details.phone,
                school: details.school,
                yearOfDegree: details.yearOfDegree,
                yearsOfExp: details.yearsOfExp,
                officeExp: details.officeExp,
                softwares: details.softwares,
                cv: cv,
                portfolio: portfolio
            }
            toast.promise(dispatch(careerUpload(newdetails)), {
                loading: 'Submitting Form',
                success: 'Submitted Form Successfully',
                error: 'Something went wrong!',
              });
        }
    }

    const handleReset = () => {
        setdetails({ ...initState })
        setCv(null)
        setPortfolio(null)
    }

    const handleCheckbox = e => {
        const {name, checked} = e.target
        var existingSoftwares = details.softwares
        let exists = existingSoftwares.includes(name)
        if(checked) {
            if(!exists) {
                existingSoftwares.push(name)
                setdetails({...details, softwares: existingSoftwares})
            }
        }
        else {
            if(exists) {
                existingSoftwares = existingSoftwares.filter(item => item !== name)
                setdetails({...details, softwares: existingSoftwares})
            }
        }
    }

    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-40'>
            <div className="grid grid-cols-12 py-10 lg:py-12 border-b border-primarygray">
                <div className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4'>
                    <h4 className='text-secondary font-visby text-2xl sm:text-3xl font-semibold'>Personal Information</h4>
                    <p className="font-visby text-primarygray text-sm sm:text-basic">Tell us something about yourself</p>
                </div>
                <div className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 mt-6 md:mt-0 '>
                    <div className="grid gap-6 mb-8 md:grid-cols-1">
                        <div>
                            <label htmlFor="fullName" className="block mb-2 font-inter text-secondary text-sm font-semibold">Full Name</label>
                            <input onChange={handleInputChange} type="text" name='fullName' value={details.fullName} className="bg-transparent text-sm md:text-base mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" placeholder="Your full name" required />
                        </div>
                        <div>
                            <label htmlFor="eamil" className="block mb-2 font-inter text-secondary text-sm font-semibold">Email</label>
                            <input onChange={handleInputChange} type="email" name='email' value={details.email} className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" placeholder="Your email address" required />
                        </div>
                        <div>
                            <label htmlFor="fullName" className="block mb-2 font-inter text-secondary text-sm font-semibold">Phone number</label>
                            <input onChange={handleInputChange} type="number" name='phone' value={details.phone} className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" placeholder="Your phone number" required />
                        </div>


                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 py-10 lg:py-12 border-b border-primarygray">
                <div className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4'>
                    <h4 className='text-secondary font-visby text-2xl sm:text-3xl font-semibold'>Questions</h4>
                    <p className="font-visby text-primarygray text-sm sm:text-basic">Please fill in additional details</p>
                </div>
                <div className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 mt-6 md:mt-0 '>
                    <div className="grid gap-6 mb-4 md:grid-cols-1">
                        <div>
                            <label htmlFor="fullName" className="block mb-2 font-inter text-secondary text-sm font-semibold">At what school did you obtain your Masters Degree?</label>
                            <input type="text" name='school' onChange={handleInputChange} value={details.school} className="bg-transparent text-sm md:text-base mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" required />
                        </div>
                        <div>
                            <label htmlFor="eamil" className="block mb-2 font-inter text-secondary text-sm font-semibold">When did you obtain your Masters Degree? (month/year)</label>
                            <input type="text" name='yearOfDegree' onChange={handleInputChange} value={details.yearOfDegree} className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" required />
                        </div>
                        <div>
                            <label htmlFor="years" className="block mb-2 font-inter text-secondary text-sm font-semibold">How many years of working experience do you have? (After graduation)</label>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="Less than a year" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-2 ml-2 text-primarygray text-sm  ">Less than a year</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="2 years" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-3 ml-2 text-primarygray text-sm  ">2 years</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="3 years" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-3 ml-2 text-primarygray text-sm  ">3 years</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="4-6 years" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-3 ml-2 text-primarygray text-sm  ">4-6 years</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="7-9 years" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-3 ml-2 text-primarygray text-sm  ">7-9 years</label>
                            </div>
                            <div className="flex items-center">
                                <input onChange={handleInputChange} name="yearsOfExp" type="radio" value="More than 10 years" className="w-4 h-4 outline-none" />
                                <label htmlFor="bordered-radio-1" className="w-full py-3 ml-2 text-primarygray text-sm  ">More than 10 years</label>
                            </div>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="eamil" className="block mb-2 font-inter text-secondary text-sm font-semibold">At what offices did you gain your experience?</label>
                            <textarea name='officeExp' onChange={handleInputChange} value={details.officeExp} className="bg-transparent text-sm md:text-base mb-3 font-fjalla text-secondary border border-primarygray  block focus:border-primarygray duration-300 px-2 rounded-md w-full py-2" required />
                        </div>
                        <div>
                            <label htmlFor="years" className="block mb-2 font-inter text-secondary text-sm font-semibold">Which are the softwares that you know?</label>
                            <div className="flex items-center">
                                <input type="checkbox" name='AutoCAD' onChange={handleCheckbox} value="No experience" className="w-4 h-4 outline-none" />
                                <label className="w-full py-2 ml-2 text-primarygray text-sm  ">AutoCAD</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='SketchUp' onChange={handleCheckbox} value="Beginner" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">SketchUp</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='Max' onChange={handleCheckbox} value="Intermediate" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">3ds Max</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='Photoshop' onChange={handleCheckbox} value="Expert" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">Photoshop</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='Lumiam' onChange={handleCheckbox} value="Expert" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">Lumiam</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='Premier Pro' onChange={handleCheckbox} value="Expert" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">Premier Pro</label>
                            </div>
                            <div className="flex items-center">
                                <input type="checkbox" name='Enscape' onChange={handleCheckbox} value="Expert" className="w-4 h-4 outline-none" />
                                <label className="w-full py-3 ml-2 text-primarygray text-sm  ">Enscape</label>
                            </div>
                        </div>
                        
                        <div className="mb-6">
                            <label htmlFor="years" className="block mb-2 font-inter text-secondary text-sm font-semibold">Upload your CV</label>
                            <input accept='application/pdf' onChange={onCvFileChange} required className="block w-full text-sm text-secondarygray border border-gray-300 rounded-lg cursor-pointer bg-lightgray questionFormFileInput" type="file" />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="years" className="block mb-2 font-inter text-secondary text-sm font-semibold">Upload your Portfolio</label>
                            <input accept='application/pdf' onChange={onPortfolioFileChange} required className="block w-full text-sm text-secondarygray border border-gray-300 rounded-lg cursor-pointer bg-lightgray questionFormFileInput" type="file" />
                        </div>
                    </div>

                </div>

            </div>
            <div className="grid grid-cols-12 py-10 lg:py-12">
                <div className='col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4'></div>
                <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-8">
                    <div className=''>
                        <button onClick={handleSubmit} className="bg-black text-primary font-visby px-10 sm:px-12 py-3 text-sm sm:text-lg font-semibold rounded-md">Submit</button>
                        <button onClick={handleReset} className="bg-black text-primary font-visby px-10 sm:px-12 py-3 text-sm sm:text-lg font-semibold rounded-md ml-3">Reset</button>
                    </div>
                </div>

            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </div>
    )
}

export default QuestionsForm