import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { contactUpload } from '../../../redux/actions'
import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
function FormSection() {
    const dispatch = useDispatch()
    const [details, setdetails] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    })
    const handleInputChange = (e) => {
        e.persist()
        setdetails((inp) => ({
            ...inp,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmitForm = (e) => {
        e.preventDefault()
        toast.promise(dispatch(contactUpload(details)), {
            loading: 'Submitting Form',
            success: 'Submitted Form Successfully',
            error: 'Something went wrong!',
          });
    }
    return (
        <div className='px-4 sm:px-14 md:px-16 lg:px-32 mt-10'>
            <h4 className='font-visby mb-10 text-xl sm:text-3xl font-semibold text-white'>Let us know how we can help you.</h4>
            <form autoComplete="off" onSubmit={handleSubmitForm}>
                <div className="grid gap-6 mb-8 md:grid-cols-2">
                    <div>
                        <input name='firstName' onChange={handleInputChange} value={details.firstName} type="text" className="bg-transparent text-sm md:text-base mb- font-fjalla text-white border-b border-primary outline-none block focus:border-primarygray duration-300 w-full py-2" placeholder="First name" required />
                    </div>
                    <div>
                        <input name='lastName' onChange={handleInputChange} value={details.lastName} type="text" className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-white border-b border-primary outline-none block focus:border-primarygray duration-300 w-full py-2" placeholder="Last name" required />
                    </div>
                    <div>
                        <input name='email' onChange={handleInputChange} value={details.email} type="email" className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-white border-b border-primary outline-none block focus:border-primarygray duration-300 w-full py-2" placeholder="Email" required />
                    </div>
                    <div>
                        <input name='phone' onChange={handleInputChange} value={details.phone} type="number" className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-white border-b border-primary outline-none block focus:border-primarygray duration-300 w-full py-2" placeholder="Phone number" required />
                    </div>
                    <div>
                        <input name='subject' onChange={handleInputChange} value={details.subject} type="text" className="bg-transparent text-sm md:text-base  mb-3 font-fjalla text-white border-b border-primary outline-none block focus:border-primarygray duration-300 w-full py-2" placeholder="Subject" required />
                    </div>

                </div>
                <div className="mb-6">
                    <label htmlFor="message" className="block mb-2 font-fjalla text-gray-400">Your message</label>
                    <textarea name='message' onChange={handleInputChange} value={details.message} className="bg-transparent text-sm md:text-base font-fjalla text-white border-b border-primary outline-none focus:border-primarygray duration-300 block w-full py-2" required />
                </div>
                <div className='mt-10 flex flex-col md:flex-row  items-center'>
                    <button type='submit' className='border border-white hover:border-secondarygray duration-300 px-8 py-3 text-xs sm:text-sm font-visby font-semibold text-primary'>Send message</button>
                    <p className='font-visby text-primary mt-6 md:mt-0 text-sm md:ml-6'>Thank you, we’ll get back to you as soon as possible.</p>
                </div>

            </form>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

        </div>
    )
}

export default FormSection