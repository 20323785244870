import React from 'react'
import brick from '../../../assets/whoarewe.png'
import '../../../styles/AboutUs/WhoAreWeSection.scss'
function WhoAreWeSection() {
  return (
    <>
    <div className='px-4 sm:px-14 md:px-16 lg:px-40 mt-10'>
      <div className="grid grid-cols-12 py-10 lg:py-16">
        <div className="col-span-8 md:col-span-4 lg:col-span-5 xl:col-span-4">
          <h2 className='font-visby leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold'>Who we are</h2>
        </div>
        <div className="col-span-12 md:col-span-2 lg:col-span-2 xl:col-span-1">
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-5">
        <p className='text-secondary font-visby font-medium mt-2  text-sm md:text-base lg:text-lg'>Our agency was founded by architects, and today we’re driven by the same fundamental mission for our clients in every industry. With a rare combination of specialist services under one roof, we’re able to combine the global perspective and meticulous detail that’s required to deliver results of visionary impact.</p>
        </div>
      </div>
    </div>
    <div className="grid grid-cols-12 lg:py-8">
      <div className='col-span-12 sm:col-span-12  md:col-span-12 lg:col-span-12 xl:col-span-12'>
          <div className='flex justify-start'>
            <img src={brick} alt="whoarewe" loading='lazy' className='whoAreWeSectionImg' />
          </div>
        </div>
      </div>
    </>
  )
}

export default WhoAreWeSection