import React, { useState } from 'react'
import call from '../../../../assets/call.png'
import '../../../../styles/Landing/ContactOptions.scss'
import {IoMdCall} from 'react-icons/io'
import {MdWifiCalling3} from 'react-icons/md'
import {RiWhatsappLine,RiWhatsappFill} from 'react-icons/ri'
import {HiOutlineMailOpen,HiOutlineMail} from 'react-icons/hi'
function ContactOptions() {
   const [option1,setOption1]=useState(false)
   const [option2,setOption2]=useState(false)
   const [option3,setOption3]=useState(false)

    return (
        <div className='sticky' >
            <div className={`fixed top-20 right-5 md:top-40 md:right-12 `}>
                <div className=" bg-black w-12 h-12  md:w-16 md:h-16 rounded-full duration-300 relative contactOptionsBox">
                    <div className="flex justify-center">
                        <img src={call} alt="call" className='mt-2 p-1 md:mt-3' />

                    </div>
                    <div onMouseEnter={()=>setOption1(true)} onMouseLeave={()=>setOption1(false)} className="contactOption1 bg-black duration-500 w-8 h-8 text-center absolute  text-white rounded-full">{option1?<HiOutlineMail className='ml-2 mt-2'/>:<HiOutlineMailOpen className='ml-2 mt-2'/>}</div>
                    <div onMouseEnter={()=>setOption2(true)} onMouseLeave={()=>setOption2(false)} className="contactOption2 bg-black duration-500 w-8 h-8 text-center  absolute  text-white rounded-full">{option2?<RiWhatsappFill className='ml-2 mt-2'/>:<RiWhatsappLine className='ml-2 mt-2'/>}</div>
                    <div onMouseEnter={()=>setOption3(true)} onMouseLeave={()=>setOption3(false)} className="contactOption3 bg-black duration-500 w-8 h-8 text-center  absolute  text-white rounded-full">{option3?<MdWifiCalling3 className='ml-2 mt-2'/>:<IoMdCall className='ml-2 mt-2'/>}</div>

                </div>
            </div>
        </div>
    )
}

export default ContactOptions