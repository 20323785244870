import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { LandingFooter } from '../Landing'
import ContactOptions from '../Landing/components/helper/ContactOptions'
import LandingNavbar from '../Landing/components/LandingNavbar'
import {AllProjectsSection, ProjectDetails} from './index'
function Projects() {
  const [hide,setHide]=useState(false)
  return (
    <div className='bg-primary'>
      
      <ContactOptions />
     
   
      <LandingNavbar  setHide={setHide} hide={hide} val={true}/>
      <Routes>
        <Route path='/' element={<AllProjectsSection hide={hide}/>}/>
        <Route path='/:id/:name' element={<ProjectDetails hide={hide} />}/>
      </Routes>
      <LandingFooter back="bg-black"/>
    </div>
  )
}

export default Projects