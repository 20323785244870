import React from 'react'
import brick from '../../../assets/brick.png'
import '../../../styles/AboutUs/BuildingBrickSection.scss'
function BuildingBrickSection() {
  return (
    <>
      <div className='px-4 sm:px-14 md:px-16 lg:px-40 '>
        <div className="grid grid-cols-12 py-10 lg:py-12">
          <div className="col-span-8 md:col-span-6 lg:col-span-8 xl:col-span-4">
            <h2 className='font-visby leading-snug text-secondary text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-semibold'>Building Trust <span className='text-primarygray'>Brick</span> by <span className='text-primarygray'>Brick</span></h2>
          </div>

        </div>
      </div>
      <div className="grid grid-cols-12 lg:py-6">
        <div className='col-span-12 sm:col-span-12  md:col-span-12 lg:col-span-12 xl:col-span-12'>
          <div className='flex justify-end'>
            <img src={brick} alt="brick" loading='lazy' className='buildingBrickSectionImg' />
          </div>
        </div>
      </div>
    </>
  )
}

export default BuildingBrickSection