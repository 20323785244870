import {CognitoUserPool, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'


const username = `${process.env.REACT_APP_COGNITO_USERNAME}`
const password = `${process.env.REACT_APP_COGNITO_PASSWORD}`

const poolData = {
    UserPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    ClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
}
export const UserPool = new CognitoUserPool(poolData)

const getAccessToken = () => {
    return new Promise((resolve, reject) => {
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        })
        
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        })
        
        user.authenticateUser(authDetails, {
            onSuccess: data => {
                const tokens = {
                    IdToken: data.getIdToken().getJwtToken(),
                    AccessToken: data.getAccessToken().getJwtToken(),
                    RefreshToken: data.getRefreshToken().getToken(),
                }
                resolve(tokens)
            },
        
            onFailure: err => {
                // console.log("Error:", err)
                reject(err)
            }
        })
    })
}

export default getAccessToken