import React from 'react'
import mjbfooter from '../../../assets/mjb_dark.png'
import { BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { FaPinterestP, FaFacebookF, FaLinkedinIn, FaBehance } from 'react-icons/fa'
import '../../../styles/Landing/LandingFooter.scss'
function LandingFooter({ back }) {
    return (
        <div className={`mt-10 sm:mt-20 px-4 md:px-12 ${back}`}>
            <div className="grid grid-cols-12 gap-6 py-10 md:py-16 lg:py-20 mt-1 md:mt-6 ">
                {/* <div className='col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-4 xl:col-span-3'>
                    <h3 className='font-visby text-primary text-base sm:text-2xl md:text-3xl lg:text-4xl font-semibold tracking-wide'>MJB ARCHITECTS</h3>
                    <div className="mt-10 flex flex-col sm:flex-row justify-between">
                        <div>
                            <a href="/" className='font-visby text-primary border-b text-xs sm:text-sm md:text-base lg:text-lg'>Home</a>
                        </div>
                        <div>
                            <a href="/projects" className='font-visby text-primary border-b text-xs sm:text-sm md:text-base lg:text-lg'>Projects</a>
                        </div>
                        <div>
                            <a href="/about-us" className='font-visby text-primary border-b text-xs sm:text-sm md:text-base lg:text-lg'>About Us</a>
                        </div>
                        <div>
                            <a href="/career" className='font-visby text-primary border-b text-xs sm:text-sm md:text-base lg:text-lg'>Career</a>
                        </div>
                        <div>
                            <a href="/contact-us" className='font-visby text-primary border-b text-xs sm:text-sm md:text-base lg:text-lg'>Contact Us</a>
                        </div>
                    </div>
                </div> */}
                {/* <div className='col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-4 xl:col-span-6'></div>
                <div className='col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-4 xl:col-span-3'>
                    <div className="flex justify-between">
                        <div className='border rounded-full p-1'>
                            <a href='/'><FaPinterestP className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className='border rounded-full p-1'>
                            <a href='/'><FaLinkedinIn className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className='border rounded-full p-1'>
                            <a href='/'><BsWhatsapp className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className='border rounded-full p-1'>
                            <a href='/'><BsInstagram className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className='border rounded-full p-1'>
                            <a href='/'><FaFacebookF className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                    </div>
                    <h5 className='text-primary text-xs sm:text-sm md:text-base lg:text-lg font-visby mt-10 mb-3 text-right'>&copy; 2023 MJB Architects. All Rights Reserved</h5>
                    <span className='font-visby text-xxs text-primarygray sm:text-sm lg:text-base float-right'>Crafted by <a className='text-primary font-visby font-semibold underline' href="https://www.writespaceinfotech.com" target="_blank" rel="noopener noreferrer">WriteSpace Infotech</a></span>
                </div> */}
                <div className="col-span-12 lg:col-span-2"></div>
                <div className="col-span-12 sm:col-span-4 lg:col-span-3 flex justify-center sm:justify-start ">
                    
                    <a href='/'><img src={mjbfooter} alt="mjbfooter" className='landingFooterImg' /></a>
                </div>
                <div className="hidden lg:block lg:col-span-1"></div>
                <div className="col-span-6 sm:col-span-4 lg:col-span-2">
                    <h3 className='text-primary font-semibold text-sm sm:text-base text-center sn:text-start'>LET'S TALK</h3>
                    <p className="text-primary mt-4 text-xs sm:text-base text-center sn:text-start">
                        mjbarchitects.in
                    </p>
                    <p className='text-primary text-xs sm:text-base mt-1 text-center sn:text-start'>Tel.: +91 98985 76357</p>
                </div>
                <div className="col-span-6 sm:col-span-4 lg:col-span-3">
                    <h3 className='text-primary font-semibold text-base text-center sn:text-start'>FOLLOW US</h3>
                    <div className="flex mt-4 space-x-4 items-center justify-center">
                        <div className=''>
                            <a href='/'><FaPinterestP className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className=''>
                            <a href='/'><FaLinkedinIn className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className=''>
                            <a href='/'><BsWhatsapp className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className=''>
                            <a href='/'><BsInstagram className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className=''>
                            <a href='/'><FaFacebookF className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                        <div className=''>
                            <a href='/'><FaBehance className='text-primary text-xs sm:text-base md:text-lg' /></a>
                        </div>
                    </div>
                </div>
                <div className="col-span-2"></div>
                <div className="col-span-8 border-t border-zinc-700 mt-4 sm:mt-8">

                </div>
                <div className="col-span-12 mt-4">
                    <h4 className='text-primary text-center text-xs sm:text-base'>&copy; 2023 MJB Architects. All Rights Reserved</h4>
                    <h5 className='mt-1 text-gray-500 text-center text-xs'>Developed and managed by <a target='_blank' className='underline underline-offset-2' href="https://www.writespaceinfotech.com/">WriteSpace Infotech</a></h5>
                </div>
            </div>
        </div>
    )
}

export default LandingFooter