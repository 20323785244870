import React from 'react'

function ProjectsFilter({ showFilter, setShowFilter, setFilterOption, setTypeName, filterOption }) {
    const handleFilterActions = (typeName) => {
        setFilterOption(typeName)
        setTypeName(typeName)
    }
    return (
        <div>
            <div className={`fixed border-l-2 border-gray-300 top-0 ${showFilter ? "translate-x-0" : "translate-x-full"} right-0 z-40 w-80 h-screen p-4 overflow-y-auto transition-transform  bg-white`} >

                <button type="button" onClick={() => setShowFilter(false)} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center " >
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Close menu</span>
                </button>
                <div className="py-8 overflow-y-auto mt-10">
                    <p className='font-visby font-semibold text-2xl'>TYPE</p>
                    <ul className="space-y-4 mt-10 font-medium">
                        {
                            filterOption !== "" && (
                                <li>
                                    <p onClick={() => {
                                        handleFilterActions("")
                                        setTypeName("ALL PROJECTS")
                                    }} className="font-visby uppercase font-medium text-lg cursor-pointer">All</p>
                                </li>
                            )
                        }
                        <li>
                            <p onClick={() => handleFilterActions("Bungalow")} className="font-visby uppercase font-medium text-lg cursor-pointer">Bungalow</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Low Rise")} className="font-visby uppercase font-medium text-lg cursor-pointer">Low Rise</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Weekend House")} className="font-visby uppercase font-medium text-lg cursor-pointer">Weekend House</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Interior")} className="font-visby uppercase font-medium text-lg cursor-pointer">Interior</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Industrial")} className="font-visby uppercase font-medium text-lg cursor-pointer">Industrial</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Commercial")} className="font-visby uppercase font-medium text-lg cursor-pointer">Commercial</p>
                        </li>
                        <li>
                            <p onClick={() => handleFilterActions("Landscape")} className="font-visby uppercase font-medium text-lg cursor-pointer">Landscape</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProjectsFilter