import AWS from "aws-sdk";
import {getSignedUrl} from '@aws-sdk/cloudfront-signer'
window.Buffer = window.Buffer || require("buffer").Buffer;

export const getS3Link = (file, IdToken) => {
  if (!file) return null;
  return new Promise((resolve, reject) => {
    AWS.config.update({
      region: "ap-south-1",
      signatureVersion: "v4",
    });

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      Logins: {
        [`${process.env.REACT_APP_COGNITO_DOMAIN}`]: IdToken,
      },
    });

    AWS.config.credentials
      .refresh(async (error) => {
        if (error) {
          console.error(error);
        } else {
          const s3 = new AWS.S3();
          const params = {
            Bucket: `${process.env.REACT_APP_S3_BUCKET}`,
            Key: `website-uploads/${Date.now()}-${file.name}`,
            Body: file,
          };
          try {
            const { Key } = await s3.upload(params).promise();
            resolve(Key);
          } catch (e) {
            // console.log(e);
            reject(e);
          }
        }
      })
  });
};

export const getSignedUrls = async urls => {
  try {    
    const keyPairId = process.env.REACT_APP_KEY_PAIR_ID
      const privateKey = process.env.REACT_APP_PRIVATE_KEY
    
      const signedUrls = {}

      urls.map(url => {    
          let dateLessThan = new Date(Date.now() + 86400)
          const signedUrl = getSignedUrl({
              url,
              keyPairId,
              dateLessThan,
              privateKey
          })
          signedUrls[url] = signedUrl
      })

      return signedUrls
    }
    catch(error) {
      console.log("Error Signing Urls:",error)
      return []
    }
}